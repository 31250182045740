/************client css start***************/
.client_logo{
    background-color: #f8faff;
    position: relative;
    
    @media #{$tab}{
        background-color: transparent !important;
    }
    .single_client_logo{
        display: flex;
        align-items: center;
        opacity: .4;

    }
    .owl-carousel .owl-item img {
        width: auto;
    }
    .single_client_logo{
        height: 120px;
        margin-right: 40px;
        @media #{$tab}{
            height: 50px;
            margin-top: 50px;
        }
        img{
            margin: 0 auto;
            height: 60px;
        }
        &#xd {
            opacity: .2;
            margin-right: 0;
        }
        &#nodejs img {
            height: 100px;
        }
        &#aws img {
            height: 40px;
        }
    }
}
